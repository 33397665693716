import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import { BigNumber } from 'bignumber.js';
import styled from 'styled-components';

import LoadingSVG from '../../../assets/svg/loading.svg';
import { useTokenBasics } from '../../hooks';
import { ChainId, SingleTransactionStatus } from '../../utils/enums';
import { decimalToInteger, integerToDecimal, toStandardHex } from '../../utils/convertors';
import Metamask from '../../../assets/metamask.svg';
import Bridge from '../../../assets/svg/exchangeBridge.svg';
import { formatLongText } from '../../utils/formatters';
import { TransactionHistory, Transactions } from '../../container';
import { CHAINS } from '../../utils/values';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'secondary' | 'disable';
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = React.memo(({ ...props }) => {
    const ButtonWrapper = styled.button`
        border: none;
        ${({ variant }) => {
            switch (variant) {
                case 'primary':
                    return `background-color: #F0B90B; color:white!important;`;
                case 'secondary':
                    return `border: 1px solid #F0B90B; background-color:white; color:#F0B90B!important;`;
                default:
                    return `background-color: white`;
            }
        }};
        min-height: 40px;
        line-height: 40px;
        min-width: 160px;
    `;
    return <ButtonWrapper {...props}>{props.children}</ButtonWrapper>;
});

const Loading: React.FC = () => {
    const LoadingWrapper = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 12;
        display: flex;
        > div:first-child {
            margin: auto;
            display: flex;
            flex-direction: column;
            > div {
                border-radius: 15px;
                color: #f2c94c;
                font-weight: bold;
                margin: auto;
            }
        }
    `;
    return (
        <LoadingWrapper>
            <div>
                <img src={LoadingSVG} />
                <div>Processing...</div>
            </div>
        </LoadingWrapper>
    );
};

if ((window as any).ethereum) {
    (window as any).web3 = new Web3((window as any).ethereum);
    (window as any).ethereum.enable();
}
declare global {
    interface Window {
        ethereum: any;
        web3: any;
    }
}

const NETWORK_CHAIN_ID_MAPS = {
    [window.env.NETWORK === 'Main' ? 1 : 3]: ChainId.Eth,
    [window.env.NETWORK === 'Main' ? 56 : 97]: ChainId.Bsc,
};

const PolyContainer = styled.div`
    box-sizing: border-box;
    width: 452px;
    padding: 40px 50px 54px;
    @media all and (max-width: 500px) {
        width: 100%;
        padding: 20px;
        margin-top: 100px;
    }
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 18px 7px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: auto;
    margin-top: 200px;
    * {
        color: white;
    }
    h1 {
        font-size: 20px;
        text-align: center;
    }
    h2 {
        opacity: 0.6;
        font-weight: 500;
        font-size: 12px;
    }
    .AssetContainer {
        display: flex;
        align-items: center;
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;
        height: 40px;
        padding: 0 14px;
        background: rgba(0, 0, 0, 0.26);
        border-radius: 4px;
        margin-top: 10px;
    }
    .IconContainer {
        margin-top: 20px;
        display: flex;
        > div {
            flex: 1;
            > div:nth-child(2) {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1;
                padding: 15px;
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                background: rgba(255, 255, 255, 0.04);
            }
            img {
                filter: brightness(0.3);
                cursor: not-allowed;
                margin: 0 20px;
            }
        }
        > div:nth-child(2) {
            display: flex;
            flex: 0;
        }
        h3 {
            margin-top: 10px;
            font-size: 11px;
            font-weight: normal;
        }
    }
    .BalanceContainer {
        margin-top: 20px;
        input {
            display: flex;
            align-items: center;
            width: -webkit-fill-available;
            width: -moz-available;
            width: stretch;
            height: 40px;
            padding: 0 14px;
            background: rgba(0, 0, 0, 0.26);
            border-radius: 4px;
            margin-top: 10px;
            border: none;
            margin-bottom: 10px;
        }
        > div {
            display: flex;
            * {
                line-height: 24px;
            }
            h2 {
                margin-right: auto;
            }
            div {
                font-size: 12px;
            }
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    button {
        flex: 1;
        color: black !important;
    }
`;

const MetaMaskContainer = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    button {
        margin: auto;
        cursor: pointer;
        min-height: 50px;
        line-height: 50px;
        font-size: 20px;
        min-width: 200px;
        border-radius: 100px;
        font-weight: bold;
    }
    > div:last-child {
        display: flex;
        grid-gap: 15px;
    }
    > div {
        margin: auto;
        cursor: pointer;
        img {
            min-width: 200px;
            min-height: 200px;
        }
    }
`;

const HistoryContainer = styled.div`
    text-align: center;
    color: white;
    margin-top: 30px;
    font-size: 14px;
    span {
        opacity: 0.6;
        color: #3ec7eb;
        text-decoration: underline;
        cursor: pointer;
    }
`;

const BridgeScreen: React.FC = () => {
    const [web33, setWeb33] = useState(null);

    const { loading, data } = useTokenBasics();
    const [curAsset, setCurAsset] = useState(null);
    const [{ from, to }, setNetwork] = useState({
        from: { chainId: null, hash: null, precision: null, name: null },
        to: { chainId: null, hash: null, precision: null, name: null },
    });
    const [amount, setAmount] = useState(null);
    const [fee, setFee] = useState(null);
    const [{ addressHex, checksumAddress, connected }, setAddress] = useState({
        addressHex: null,
        checksumAddress: null,
        connected: false,
    });
    const [allowance, setAllowance] = useState(null);
    const [balance, setBalance] = useState(null);
    const [{ transactionHash }, setTransactionHash] = useState({
        transactionHash: null,
        status: null,
    });
    const [isLoading, setLoading] = useState(false);
    const [transactionModal, setTransactionModal] = useState(false);
    const [transactionHistoryModal, setTransactionHistoryModal] = useState(false);

    const getAllowance = async ({ address, tokenHash, spender }) => {
        try {
            if (tokenHash === '0000000000000000000000000000000000000000') {
                return null;
            }
            const tokenContract = new web33.eth.Contract(
                require('../../abi/erc20.json'),
                tokenHash
            );

            const result = await tokenContract.methods.allowance(address, `${spender}`).call();
            return new BigNumber(result).shiftedBy(-from.precision).toFixed();
        } catch (error) {
            setLoading(false);
            // addToast('Get allowance failed', { appearance: 'error' });
        }
    };

    const asyncFunction = async () => {
        try {
            const { lockContractHash } = CHAINS.find((e) => e.id === +from.chainId);
            setAllowance(
                await getAllowance({
                    address: checksumAddress,
                    tokenHash: from.hash,
                    spender: lockContractHash,
                })
            );
        } catch (e) {
            setLoading(false);
            // addToast('Get allowance failed', { appearance: 'error' });
        }
    };
    const getBalance = async ({ address, tokenHash, precision }) => {
        try {
            if (tokenHash === '0000000000000000000000000000000000000000') {
                const result = await web33.eth.getBalance(address);
                return integerToDecimal(result, from.precision ? from.precision : precision);
            }
            const tokenContract = new web33.eth.Contract(
                require('../../abi/erc20.json'),
                tokenHash
            );
            const result = await tokenContract.methods.balanceOf(address).call();
            return integerToDecimal(result, from.precision ? from.precision : precision);
        } catch (error) {
            setLoading(false);
            // addToast("Can't get Balance", { appearance: 'error' });
        }
    };

    const confirmLater = (promise) => {
        return new Promise((resolve, reject) => {
            promise.on('transactionHash', resolve);
            promise.on('error', reject);

            function onConfirm(_confNumber, _receipt) {
                promise.off('confirmation', onConfirm);
            }
            promise.on('confirmation', onConfirm);
        });
    };

    const needApproval = () => {
        return !!amount && !!allowance && new BigNumber(amount).gt(allowance);
    };

    const getTransactionStatus = async ({ transactionHash }) => {
        try {
            const transactionReceipt = await web33.eth.getTransactionReceipt(
                `0x${transactionHash}`
            );
            if (transactionReceipt) {
                return transactionReceipt.status
                    ? SingleTransactionStatus.Done
                    : SingleTransactionStatus.Failed;
            }
            return SingleTransactionStatus.Pending;
        } catch (error) {
            setLoading(false);
            // addToast('getTransactionStatus error', { appearance: 'error' });
        }
    };

    const lock = async ({ fromAddress, fromTokenHash, toChainId, toAddress, amount, fee }) => {
        try {
            setLoading(true);
            const { lockContractHash, nftFeeName } = CHAINS.find((e) => e.id === +from.chainId);
            const lockContract = new web33.eth.Contract(
                require('../../abi/eth-lock.json'),
                lockContractHash
            );
            const toAddressHex = addressToHex(toAddress);
            const amountInt = decimalToInteger(amount, from.precision);
            const feeInt = decimalToInteger(fee, nftFeeName ? 18 : from.precision);
            const result = await confirmLater(
                lockContract.methods
                    .lock(
                        `0x${fromTokenHash}`,
                        toChainId,
                        `0x${toAddressHex}`,
                        amountInt,
                        feeInt,
                        0
                    )
                    .send({
                        from: fromAddress,
                        value:
                            fromTokenHash === '0000000000000000000000000000000000000000'
                                ? amountInt
                                : feeInt,
                    })
            );
            return toStandardHex(result);
        } catch (error) {
            setLoading(false);
            // addToast('Lock error', { appearance: 'error' });
            setTransactionModal(false);
        }
    };

    const confirm = async () => {
        try {
            setLoading(true);
            const delay = (ms) => new Promise((_) => setTimeout(_, ms));
            const transactionHash = await lock({
                fromAddress: checksumAddress,
                fromTokenHash: from.hash,
                toChainId: to.chainId,
                toAddress: checksumAddress,
                amount,
                fee,
            });
            setTransactionHash((p) => ({ ...p, transactionHash }));
            if (transactionHash) {
                let status = SingleTransactionStatus.Pending;
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    try {
                        // eslint-disable-next-line no-await-in-loop
                        status = await getTransactionStatus({ transactionHash });
                        if (status !== SingleTransactionStatus.Pending) {
                            break;
                        }
                        setTransactionHash((p) => ({ ...p, status }));
                        // eslint-disable-next-line no-await-in-loop
                        await delay(5000);
                    } catch (error) {
                        // ignore error
                        console.error(error);
                    }
                }
            }
        } finally {
            setTransactionModal(true);
            setLoading(false);
            asyncFunction();
        }
    };

    const approve = async ({ address, tokenHash, spender, amount }) => {
        try {
            setLoading(true);
            const amountInt = new BigNumber(amount).shiftedBy(from.precision).dp(0).toFixed();
            const tokenContract = new web33.eth.Contract(
                require('../../abi/erc20.json'),
                tokenHash
            );
            return await tokenContract.methods.approve(`${spender}`, amountInt).send({
                from: address,
            });
        } catch (error) {
            setLoading(false);
            // addToast('Approved error', { appearance: 'error' });
        }
    };

    function isValidHex(hex) {
        return typeof hex === 'string' && /^(0[xX])?([0-9A-Fa-f]{2})*$/.test(hex);
    }

    function addressToHash(address) {
        if (!isValidHex(address)) {
            throw new Error('input param is not a valid hex string');
        }
        return address.replace(/0[xX]/, '').toLowerCase();
    }

    function addressToHex(address) {
        return addressToHash(address);
    }

    const queryState = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        const address = accounts[0] || null;
        const addressHex = await addressToHex(address);
        const checksumAddress = address && web33.utils.toChecksumAddress(address);
        const network = await window.ethereum.request({ method: 'eth_chainId' });
        setAddress((p) => ({ ...p, addressHex, checksumAddress, network, connected: true }));
    };

    const connect = async () => {
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            await queryState();
            sessionStorage.setItem('META_MASK_CONNECTED', 'true');
        } catch (error) {
            setLoading(false);
            // addToast(error, { appearance: 'error' });
        }
    };

    const tempMethods = async () => {
        try {
            const { lockContractHash } = CHAINS.find((e) => e.id === +from.chainId);
            await approve({
                address: checksumAddress,
                tokenHash: from.hash,
                spender: lockContractHash,
                amount: amount,
            });
        } finally {
            asyncFunction();
            setLoading(false);
        }
    };
    const selectedChain = async (e) => {
        try {
            if (NETWORK_CHAIN_ID_MAPS[window.ethereum.networkVersion] !== e.ChainId) {
                throw 'Please change correct network';
            }
            setAddress((p) => ({
                ...p,
                chainId: NETWORK_CHAIN_ID_MAPS[window.ethereum.networkVersion],
            }));
            const { data } = await axios.post('https://bridge.poly.network/v1/tokenmap', {
                ChainId: +e.ChainId,
                Hash: e.Hash,
            });

            setNetwork((p) => ({
                ...p,
                from: {
                    chainId: e.ChainId,
                    hash: e.Hash,
                    precision: data.TokenMaps[0]['SrcToken'].Precision,
                    name: data.TokenMaps[0]['SrcToken'].Name,
                },
                to: {
                    chainId: data.TokenMaps[0]['DstToken'].ChainId,
                    hash: data.TokenMaps[0]['DstToken'].Hash,
                    precision: data.TokenMaps[0]['DstToken'].Precision,
                    name: data.TokenMaps[0]['DstToken'].Name,
                },
            }));
            const curBalance = await getBalance({
                address: checksumAddress,
                tokenHash: e.Hash,
                precision: data.TokenMaps[0]['SrcToken'].Precision,
            });
            setBalance(curBalance);
            const { nftFeeContractHash } = CHAINS.find((i) => i.id === +e.ChainId);
            const { data: feeData } = await axios.post('https://bridge.poly.network/v1/getfee', {
                DstChainId: data.TokenMaps[0]['DstToken'].ChainId,
                Hash: nftFeeContractHash ? nftFeeContractHash : e.Hash,
                SrcChainId: data.TokenMaps[0]['SrcToken'].ChainId,
            });
            setFee(feeData.TokenAmount);
        } catch (e) {
            console.error(e);
            setLoading(false);
            // addToast('chain error', { appearance: 'error' });
        }
    };

    const setPolyFunc = () => {
        const currentChainIdMatchCurasset = curAsset.Tokens.find(
            (e) => e.ChainId === NETWORK_CHAIN_ID_MAPS[window.ethereum.networkVersion]
        );
        if (currentChainIdMatchCurasset) {
            selectedChain({ ...currentChainIdMatchCurasset });
        } else {
            // addToast('switch mainnet', { appearance: 'error' });
        }
    };

    const init = async () => {
        try {
            if (!window.ethereum) {
                return;
            }
            setWeb33(new Web3(window.ethereum));
        } catch (e) {
            setLoading(false);
            // addToast(e, { appearance: 'error' });
        }
        window.ethereum.on('accountsChanged', async () => {
            window.location.reload();
        });

        window.ethereum.on('chainChanged', () => {
            window.location.reload();
        });
    };

    useEffect(() => {
        if (web33 && sessionStorage.getItem('META_MASK_CONNECTED') === 'true') {
            queryState();
        }
    }, [web33]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setCurAsset(data?.TokenBasics.find((e) => e.Name === 'AQT'));
        }
    }, [loading, data]);

    useEffect(() => {
        if (from.hash) {
            asyncFunction();
        }
    }, [from, checksumAddress]);

    useEffect(() => {
        if (data && checksumAddress && curAsset) {
            console.warn(data, checksumAddress, curAsset);
            setPolyFunc();
        }
    }, [data, curAsset, checksumAddress]);

    const handleCurrentChainIdToName = () => {
        return NETWORK_CHAIN_ID_MAPS[window.ethereum.networkVersion] === 2
            ? { from: 'ETH', to: 'BSC' }
            : { from: 'BSC', to: 'ETH' };
    };

    const computedChainName = () => {
        const fromChain = CHAINS.find((e) => e.id === +from.chainId);
        return fromChain.nftFeeName ? fromChain.nftFeeName : from.name;
    };
    return (
        <React.Fragment>
            {!connected ? (
                <MetaMaskContainer>
                    <div onClick={connect}>
                        <img src={Metamask} alt="metamask" />
                    </div>
                    <div>
                        <Button variant="primary" onClick={connect}>
                            Metamask Connect
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => window.open('https://metamask.io/download.html')}>
                            Download
                        </Button>
                    </div>
                </MetaMaskContainer>
            ) : (
                <React.Fragment>
                    <PolyContainer>
                        <h1>Bridge</h1>
                        <h2>Asset</h2>
                        <div className="AssetContainer">{curAsset?.Name}</div>
                        <div className="IconContainer">
                            <div>
                                <h2>From</h2>
                                <div>{handleCurrentChainIdToName().from}</div>
                                <h3>{formatLongText(checksumAddress, { headTailLength: 8 })}</h3>
                            </div>
                            <div>
                                <img src={Bridge} alt="bridge" />
                            </div>
                            <div>
                                <h2>To</h2>
                                <div>{handleCurrentChainIdToName().to}</div>
                                <h3>{formatLongText(checksumAddress, { headTailLength: 8 })}</h3>
                            </div>
                        </div>
                        <div className="BalanceContainer">
                            <h2>Amount</h2>
                            <input
                                type="text"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <div>
                                <h2>Balance</h2>
                                <div>
                                    {balance}
                                    {curAsset?.Name}
                                </div>
                            </div>
                            <div>
                                <h2>Fee</h2>
                                <div>
                                    {fee} {computedChainName()}
                                </div>
                            </div>

                            <ButtonWrapper>
                                {needApproval() ? (
                                    <Button variant={'primary'} onClick={tempMethods}>
                                        APPROVE
                                    </Button>
                                ) : (
                                    <Button
                                        variant={'primary'}
                                        disabled={!allowance || !amount}
                                        onClick={confirm}>
                                        Confirm
                                    </Button>
                                )}
                            </ButtonWrapper>
                        </div>
                    </PolyContainer>
                    <HistoryContainer>
                        You can view your{' '}
                        <span onClick={() => setTransactionHistoryModal(true)}>history</span>
                    </HistoryContainer>
                </React.Fragment>
            )}
            {isLoading && <Loading />}
            {transactionModal && (
                <Transactions
                    transactionHash={transactionHash}
                    close={() => setTransactionModal(false)}
                />
            )}
            {transactionHistoryModal && (
                <TransactionHistory
                    address={addressHex}
                    close={() => setTransactionHistoryModal(false)}
                    openTransaction={(e) => {
                        setTransactionHash((p) => ({ ...p, transactionHash: e }));
                        setTransactionModal(true);
                    }}
                />
            )}
        </React.Fragment>
    );
};
export default BridgeScreen

