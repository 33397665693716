import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(advancedFormat);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatEnum(value, { type, ingoreMissing = false }) {
    if (!value && value !== 0) {
        return '';
    }
    if (!ingoreMissing) {
        console.warn(`Enum path not found: ${`enums.${type}.${value}`}`);
    }
    return value;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatNumber(value, { decimals = null, symbol = null, toExp = false }) {
    const bn = new BigNumber(value);
    if (bn.isNaN()) return '-';

    const options = {
        prefix: symbol != null ? `${symbol} ` : '',
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
    };

    let ret = null;
    if (toExp && !bn.eq(0)) {
        ret = bn.toExponential(decimals);
    } else if (decimals != null) {
        ret = bn.dp(decimals).toFormat(options);
    } else {
        ret = bn.toFormat(options);
    }
    return ret;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatPercentage(value, { decimals = null, displaySign = false }) {
    const bn = new BigNumber(value);
    if (bn.isNaN()) return '- %';

    const options = {
        suffix: ' %',
        decimalSeparator: '.',
    };
    let ret = null;
    if (decimals != null) {
        ret = bn.shiftedBy(2).dp(decimals).toFormat(options);
    } else {
        ret = bn.shiftedBy(2).toFormat(options);
    }

    if (displaySign && bn.isPositive()) {
        ret = `+${ret}`;
    }
    return ret;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatTime(value) {
    if (!value) {
        return '';
    }
    return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formatLongText(value, { headTailLength = 8 }) {
    if (!value) {
        return '';
    }
    if (value.length <= headTailLength * 2 + 3) {
        return value;
    }
    return `${value.slice(0, headTailLength)}...${value.slice(-headTailLength)}`;
}
