import { WalletName, ChainId } from './enums';

export const HTTP_BASE_URL =
    window.env.NETWORK === 'Main'
        ? 'https://bridge.poly.network/v1'
        : 'https://bridge.poly.network/testnet/v1';

export const HTTP_NFT_BASE_URL =
    window.env.NETWORK === 'Main'
        ? 'https://bridge.poly.network/nft/v1'
        : 'https://bridge.poly.network/testnet/nft/v1';

export const WALLETS = [
    {
        name: WalletName.MetaMask,
        supportedChainIds: [ChainId.Eth, ChainId.Bsc, ChainId.Heco, ChainId.Ok],
        icon: require('../../assets/svg/meta-mask.svg'),
        downloadUrl:
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
    },
    {
        name: WalletName.NeoLine,
        supportedChainIds: [ChainId.Neo],
        icon: require('../../assets/svg/neoline.svg'),
        downloadUrl:
            'https://chrome.google.com/webstore/detail/neoline/cphhlgmgameodnhkjdmkpanlelnlohao',
    },
    {
        name: WalletName.O3,
        supportedChainIds: [ChainId.Neo],
        icon: require('../../assets/svg/o3.svg'),
        downloadUrl: 'https://o3.network/#download',
    },
    {
        name: WalletName.Binance,
        supportedChainIds: [ChainId.Bsc],
        icon: require('../../assets/svg/binance.svg'),
        downloadUrl:
            'https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp',
    },
    {
        name: WalletName.Cyano,
        supportedChainIds: [ChainId.Ont],
        icon: require('../../assets/svg/ONT.svg'),
        downloadUrl:
            'https://chrome.google.com/webstore/detail/cyano-wallet/dkdedlpgdmmkkfjabffeganieamfklkm',
    },
    // ...(window.env.NETWORK === "Main"
    //   ? [
    {
        name: WalletName.WalletConnect,
        supportedChainIds: [ChainId.Eth],
        icon: require('../../assets/svg/wallet-connect.svg'),
        downloadUrl: 'https://walletconnect.org/wallets',
    },
    //   ]
    // : []),
];

export const CHAINS = [
    {
        id: ChainId.Poly,
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'http://explorer.poly.network/tx/{txHash}'
                : 'http://explorer.poly.network/testnet/tx/{txHash}',
        nftexplorerUrl:
            window.env.NETWORK === 'Main'
                ? 'http://explorer.poly.network/nfttx/{txHash}'
                : 'http://explorer.poly.network/testnet/nfttx/{txHash}',
    },
    {
        id: ChainId.Eth,
        icon: require('../../assets/svg/eth.svg'),
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://etherscan.io/tx/0x{txHash}'
                : 'https://ropsten.etherscan.io/tx/0x{txHash}',
        nftexplorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://etherscan.io/tx/0x{txHash}'
                : 'https://ropsten.etherscan.io/tx/0x{txHash}',
        lockContractHash:
            window.env.NETWORK === 'Main'
                ? '0xd380450e9e373bDC389951C54616edb2EE653524'
                : //   : '0xe498fb7D00468a67A79dE5D4Ca264d3350165280',
                  '0xDc37471Af6a8aB7f45F444c5a3Ef4758281bE32C',
        nftLockContractHash:
            window.env.NETWORK === 'Main'
                ? '0xe5204d62361A353C665668C858Ea213070CA159c'
                : '0x940300dc3Fc26e3A330a300be766184C0b5Fe019',
        nftFeeContractHash: '0000000000000000000000000000000000000000',
        nftFeeName: 'ETH',
    },
    {
        id: ChainId.Neo,
        icon: require('../../assets/svg/neo.svg'),
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://neotube.io/transaction/0x{txHash}'
                : 'https://testnet.neotube.io/transaction/0x{txHash}',
        lockContractHash:
            window.env.NETWORK === 'Main'
                ? '125c83403763670c215f9c7c815ef759b258a41b'
                : 'cd074cd290acc3d73c030784101afbcf40fd86a1',
    },
    {
        id: ChainId.Bsc,
        icon: require('../../assets/svg/bsc.svg'),
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://bscscan.com/tx/0x{txHash}'
                : 'https://testnet.bscscan.com/tx/0x{txHash}',
        nftexplorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://bscscan.com/tx/0x{txHash}'
                : 'https://testnet.bscscan.com/tx/0x{txHash}',
        lockContractHash:
            window.env.NETWORK === 'Main'
                ? '0x4b0CFdb5e1Ca898a225F9E14AfF9e4eF178a10d2'
                : //  : '0xCed7997C3e807Fcdc5ac18fFC0B8af93a15a9eE5',
                  '0x9f9F15CC407F7b26f55D71D43f993580a9107007',
        nftLockContractHash:
            window.env.NETWORK === 'Main'
                ? '0xe2bD9dD8FAF5C4C2087Ab82eC7E63F619CcAa152'
                : '0x61E289D43C1FEA7598786557A2F309979ad144D3',
        nftFeeContractHash: '0000000000000000000000000000000000000000',
        nftFeeName: 'BNB',
    },
    {
        id: ChainId.Heco,
        icon: require('../../assets/svg/heco.svg'),
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://hecoinfo.com/tx/0x{txHash}'
                : 'https://testnet.hecoinfo.com/tx/0x{txHash}',
        nftexplorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://hecoinfo.com/tx/0x{txHash}'
                : 'https://testnet.hecoinfo.com/tx/0x{txHash}',
        lockContractHash:
            window.env.NETWORK === 'Main'
                ? '0x1B0C55be400e2a7D924032B257Fbc75Bbfd256E7'
                : //  : '0x3c92F1E31aACA43Eb4fF8aE498C7E85618680F45',
                  '0xCC8407Ee04AaC2AdC0E6A55E7E97176C701146cd',
        nftLockContractHash:
            window.env.NETWORK === 'Main'
                ? '0xe2bD9dD8FAF5C4C2087Ab82eC7E63F619CcAa152'
                : '0xbaBaAF5CF7f63437755aAAFE7a4106463c5cD540',
        nftFeeContractHash: '0000000000000000000000000000000000000000',
        nftFeeName: 'HT',
    },
    {
        id: ChainId.Ok,
        icon: require('../../assets/svg/ok.svg'),
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://www.oklink.com/okexchain/tx/0x{txHash}'
                : 'https://www.oklink.com/okexchain-test/tx/0x{txHash}',
        lockContractHash:
            window.env.NETWORK === 'Main'
                ? '0xbd4a08577476A521C41a21EF3f00b045b74175d5'
                : //  : '0x5598226aD2E8991deEFD03203739C87BdF6e6D03',
                  '0xbAa40bBd7888f79614057d82bdcE88dF2D23bf55',
        nftFeeContractHash: '0000000000000000000000000000000000000000',
        nftFeeName: 'OKT',
    },
    {
        id: ChainId.Ont,
        icon: require('../../assets/svg/ONT.svg'),
        explorerUrl:
            window.env.NETWORK === 'Main'
                ? 'https://explorer.ont.io/transaction/{txHash}'
                : 'https://explorer.ont.io/transaction/{txHash}/testnet',
        lockContractHash:
            window.env.NETWORK === 'Main'
                ? 'c93837e82178d406af8c84e1841c6960af251cb5'
                : 'a5c101afa9e04e9dd2c912983795005a49e02efa',
    },
];

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const UNKNOWN_ICON = require('../../assets/svg/unknown.svg');

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const UNKNOWN_NFT = require('../../assets/png/nft.png');

export const TOKEN_BASIC_ICONS = {
    NEO: require('../../assets/svg/neo-token.svg'),
    nNEO: require('../../assets/svg/neo-token.svg'),
    ETH: require('../../assets/svg/eth-token.svg'),
    USDT: require('../../assets/svg/usdt.svg'),
    USDC: require('../../assets/svg/usdc.svg'),
    DAI: require('../../assets/svg/dai.svg'),
    sUSD: require('../../assets/svg/susd.svg'),
    BAC: require('../../assets/svg/bac.svg'),
    BASv2: require('../../assets/svg/basv2.svg'),
    CWS: require('../../assets/png/cws.png'),
    SHARE: require('../../assets/svg/share.svg'),
    FLM: require('../../assets/svg/flm.svg'),
    SWTH: require('../../assets/svg/swth.svg'),
    ONTd: require('../../assets/svg/ONT.svg'),
    WING: require('../../assets/svg/wing.svg'),
    YFI: require('../../assets/svg/YFI.svg'),
    UNI: require('../../assets/svg/UNI.svg'),
    UNFI: require('../../assets/svg/UNFI.svg'),
    MDX: require('../../assets/svg/mdx.svg'),
    WBTC: require('../../assets/svg/WBTC.svg'),
    renBTC: require('../../assets/svg/renBTC.svg'),
    COOK: require('../../assets/svg/cook.svg'),
    FEI: require('../../assets/png/fei.png'),
    Tribe: require('../../assets/png/tribe.png'),
    YNI: require('../../assets/png/yni.png'),
    REVO: require('../../assets/svg/revo.svg'),
    revo: require('../../assets/svg/revo.svg'),
    Revo: require('../../assets/svg/revo.svg'),
    ESS: require('../../assets/svg/ESS.svg'),
    Bles: require('../../assets/svg/bles.svg'),
    BLES: require('../../assets/svg/bles.svg'),
    aDAI: require('../../assets/svg/adai.svg'),
    aETH: require('../../assets/svg/aeth.svg'),
    cDAI: require('../../assets/svg/cdai.svg'),
    cETH: require('../../assets/svg/ceth.svg'),
    FLUX: require('../../assets/svg/flux.svg'),
    CVT: require('../../assets/svg/cvt.svg'),
    KEL: require('../../assets/png/kel.png'),
    SHIB: require('../../assets/png/shib.png'),
    Shib: require('../../assets/png/shib.png'),
    STACK: require('../../assets/png/stack.png'),
    CC: require('../../assets/png/ccswap.png'),
    PLF: require('../../assets/png/plf.png'),
    TSX: require('../../assets/png/tsx.png'),
    O3: require('../../assets/png/o3.png'),
    KISHU: require('../../assets/png/kishu.png'),
    CELL: require('../../assets/png/cell.png'),
    ECELL: require('../../assets/svg/ecell.svg'),
    ISM: require('../../assets/jpg/ism.jpg'),
    BBANK: require('../../assets/jpg/bbank.jpg'),
    DOWS: require('../../assets/svg/shadows.svg'),
    PLUT: require('../../assets/svg/plut.svg'),
    mBTM: require('../../assets/svg/mbtm.svg'),
    LEV: require('../../assets/svg/lev.svg'),
    XTM: require('../../assets/svg/xtm.svg'),
    XTF: require('../../assets/svg/xtf.svg'),
    TAP: require('../../assets/svg/tap.svg'),
    '8PAY': require('../../assets/jpg/8pay.jpg'),
};

export const DEFAULT_TOKEN_BASIC_NAME = 'USDT';
export const DEFAULT_CHAIN_NAME = 'ETH';

export const TOP_TOKEN_BASIC_NAMES = ['NEO', 'nNEO', 'ETH', 'USDT', 'USDC', 'DAI'];
