import axios from 'axios';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTokenBasics = () => {
    const [{ loading, data, error }, setState] = useState({ loading: false, data: null, error: false });

    const getTokensWithAxios = async () => {
        try {
            setState((p) => ({ ...p, loading: true }));
            const { data } = await axios.post(`https://bridge.poly.network/v1/tokenbasics`, {});
            setState((p) => ({ ...p, loading: false, data }));
        } catch (e) {
            setState((p) => ({ ...p, error: true }));
        }
    };

    useEffect(() => {
        getTokensWithAxios();
    }, []);

    return { loading, data, error };
};
