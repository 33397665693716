export const WalletName = {
    MetaMask: 'MetaMask',
    NeoLine: 'NeoLine',
    O3: 'O3',
    Binance: 'Binance',
    Cyano: 'Cyano',
    WalletConnect: 'WalletConnnect',
};

export const ChainId = {
    Poly: 0,
    Eth: 2,
    Ont: 3,
    Neo: window.env.NETWORK === 'Main' ? 4 : 5,
    Bsc: window.env.NETWORK === 'Main' ? 6 : 79,
    Heco: 7,
    Ok: window.env.NETWORK === 'Main' ? 12 : 200,
};

export const ChainIdReverse = {
    0: 'Poly',
    2: 'Eth',
    3: 'Ont',
    [window.env.NETWORK === 'Main' ? 4 : 5]: 'Neo',
    [window.env.NETWORK === 'Main' ? 6 : 79]: 'Bsc',
    7: 'Heco',
    [window.env.NETWORK === 'Main' ? 12 : 200]: 'Ok',
};

export const SingleTransactionStatus = {
    Failed: -1,
    Pending: 1,
    Done: 2,
};

export const TransactionStatus = {
    Failed: -1,
    Finished: 0,
    Pending: 1,
    SourceDone: 2,
    SourceConfirmed: 3,
    PolyConfirmed: 4,
};
