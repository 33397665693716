import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { useInterval } from '../../hooks/useInterval';
import { formatLongText } from '../../utils/formatters';
import { ChainIdReverse } from '../../utils/enums';
import Loading from '../../../assets/svg/loading.svg';
import { CHAINS } from '../../utils/values';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 12;
    color: white;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1000px) {
        padding: 20px;
    }
    > div {
        margin: auto;
    }
    h1 {
        font-weight: 600;
        font-size: 40px;
        text-align: center;
    }
`;

const TransactionWrapper = styled.div`
    text-align: center;
    button {
        margin: auto;
        border: 1px solid #fff;
        padding: 10px;
        background: none;
        border-radius: 8px;
        color: white;
        margin-top: 20px;
    }
`;
const ChainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    * {
        font-size: 13px;
        line-height: 18px;
    }
    a {
        opacity: 0.6;
        color: #3ec7eb;
        font-size: 14px;
        text-decoration: underline;
    }
    > div {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        > div {
            display: flex;
            > div:last-child {
                margin-left: auto;
            }
        }
    }
`;

export const Transactions: React.FC<any> = ({ transactionHash, close }) => {
    const [transactionData, setTransactionData] = useState(null);

    const fetchTransactions = async (hash) => {
        try {
            const { data } = await axios.post('https://bridge.poly.network/v1/transactionofhash', {
                Hash: hash,
            });
            setTransactionData(null);
            setTransactionData(data);
        } catch (e) {
            console.error(e);
        }
    };

    useInterval(() => {
        fetchTransactions(transactionHash);
    }, 5000);

    useEffect(() => {
        if (transactionHash) {
            fetchTransactions(transactionHash);
        }
    }, [transactionHash]);
    return (
        <Container>
            <div>
                <h1>Transaction Details</h1>
                <TransactionWrapper>
                    {transactionData ? (
                        transactionData?.TransactionState?.map((e) => {
                            return (
                                <ChainWrapper key={e.Hash}>
                                    <div>
                                        <div>
                                            <div>ChainId</div>
                                            <div>{ChainIdReverse[e.ChainId]}</div>
                                        </div>
                                        <div>
                                            <div>Hash</div>
                                            <div>
                                                {e.Hash ? (
                                                    <a
                                                        href={CHAINS.find(
                                                            (i) => i.id === e.ChainId
                                                        ).explorerUrl.replace('{txHash}', e.Hash)}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        {`Hash: ${formatLongText(e.Hash, {
                                                            headTailLength: 8,
                                                        })}`}
                                                    </a>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <div>Blocks</div>
                                            <div>{`${e.Blocks} / ${e.NeedBlocks}`}</div>
                                        </div>
                                    </div>
                                </ChainWrapper>
                            );
                        })
                    ) : (
                        <div style={{ display: 'flex', margin: 'auto', flexDirection: 'column' }}>
                            <img
                                src={Loading}
                                style={{ width: '150px', height: 'auto', margin: 'auto' }}
                            />
                            <div style={{ textAlign: 'center' }}>Processing...</div>
                        </div>
                    )}
                    <button onClick={close}>Close</button>
                </TransactionWrapper>
            </div>
        </Container>
    );
};
