import React from 'react';
import styled from 'styled-components';

import IV from '../../../assets/investor.svg';
import CA from '../../../assets/ca.svg';
import Translate from '../../../assets/translate.svg';
import Triangle from '../../../assets/triangle.svg';
import Holding from '../../../assets/holding.svg';
import Convert from '../../../assets/convert.svg';
import Doughnut from '../../../assets/doughnut.svg';
import Bithumb from '../../../assets/partner/bithumb.svg';
import FM from '../../../assets/partner/fm.svg';
import GateIO from '../../../assets/partner/gateio.svg';
import GoPAX from '../../../assets/partner/gopax.svg';
import KMGA from '../../../assets/partner/kmga.svg';
import PayProtocol from '../../../assets/partner/payprotocols.svg';
import Upbit from '../../../assets/partner/upbit.svg';
import WXY from '../../../assets/partner/wxy.svg';
import Back from '../../../assets/member/back.svg';
import Daniel from '../../../assets/member/daniel.svg';
import Kyle from '../../../assets/member/kyle.svg';
import Kyung from '../../../assets/member/kyung.svg';
import Lucia from '../../../assets/member/lucia.svg';
import Terry from '../../../assets/member/terry.svg';
import PolyNetwork from '../../../assets/partner/polynetwork.png';
import MattWallet from '../../../assets/partner/mattwallet.png';
import Adappter from '../../../assets/partner/adappter.png';
import Hotbit from '../../../assets/partner/hotbit.png';
import TresureLend from '../../../assets/partner/treasureland.png';
import Sona from '../../../assets/partner/sona.svg';
import Temp from '../../../assets/partner/temp.png';
import { ImgWrapper } from '../styled';
import { en } from '../../components/locale/en';

const TeamWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 100px;
    margin: 230px 0;

    > div {
        display: flex;
    }
    > div:first-child {
        margin: auto;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
    }
    > div:last-child {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 60px;
        max-width: 910px;
        margin: auto;
        > div {
            display: flex;
            flex-direction: column;
            flex: 0;
            margin: auto;
            flex-wrap: wrap;
            grid-gap: 34px;
            img {
                min-width: 240px;
            }
            > div:last-child {
                display: flex;
                flex-direction: column;
                grid-gap: 12px;
                > div:last-child {
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;
                    letter-spacing: 0.03em;
                    color: rgba(0, 0, 0, 0.5);
                }
                > div {
                    margin: auto;
                }
                * {
                    text-align: center;
                    font-size: 20px;
                    line-height: 23px;
                    text-align: center;
                    letter-spacing: -0.01em;
                }
            }
        }
    }
`;

const PartnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 150px;
    > div {
        display: flex;
        flex-direction: column;
        grid-gap: 64px;

        > div:first-child {
            font-weight: bold;
            font-size: 28px;
            line-height: 30px;
            text-align: center;
        }
        > div:last-child {
            display: flex;
            margin: auto;
            grid-gap: 30px;
            flex-wrap: wrap;
            > div {
                margin: auto;
            }
        }
    }
`;

const TranslateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div:first-child {
        font-size: 28px;
        line-height: 30px;
        margin: auto;
    }
    > div:last-child {
        margin: auto;
        margin-top: 150px;
        margin-bottom: 230px;
    }
`;

const TokenInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top:150px;
    * {
        text-align: center;
    }
    > div:first-child {
        font-size: 28px;
        line-height: 30px;
        margin: auto;
    }
    > div:last-child {
        display: flex;
        flex-direction: column;
        > div:first-child {
            display: flex;
            grid-gap: 70px;
            margin-top: 70px;
            margin-bottom: 100px;
            @media all and (max-width: 1000px) {
                flex-wrap: wrap;
            }
            > div {
                margin: auto;
                display: flex;
                flex-direction: column;
                grid-gap: 6px;
                > div:first-child {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                    opacity: 0.6;
                }
                > div:last-child {
                    color: #f83d3d;
                    font-size: 13px;
                    line-height: 26px;
                }
            }
        }
        > div:nth-child(2) {
            margin: auto;
        }
        > div:last-child {
            margin-top: 80px;
            white-space: pre-wrap;
            font-size: 16px;
            line-height: 26px;
            color: #c2c2c2;
        }
    }
`;

const TriangleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        margin: auto;
    }
    > div:first-child {
        font-weight: 500;
        font-size: 28px;
        line-height: 30px;
    }
    > div:last-child {
        margin-top: 120px;
        margin-bottom: 300px;
    }
`;

const FeatureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div:first-child {
        font-size: 28px;
        line-height: 30px;
        margin: auto;
    }
    > div:last-child {
        margin-top: 90px;
        margin-bottom: 230px;
        display: flex;
        > div {
            max-width: 343px;
            flex: 1;
            margin: auto;
            margin-top: 0;
            * {
                text-align: center;
                filter: brightness(0);
            }
            > div:first-child {
                min-height: 85px;
            }
            > div:nth-child(2) {
                min-height: 60px;
                display: flex;
                flex-direction: column;
                margin-top: 45px;
                margin-bottom: 30px;
                > div {
                    margin: auto;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                }
            }
            > div:last-child {
                font-size: 14px;
                line-height: 26px;
                opacity: 0.5;
            }
        }
    }
`;

const IPFiWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div:first-child {
        font-size: 28px;
        line-height: 30px;
        margin: auto;
    }
    > div:last-child {
        margin-top: 130px;
        margin-bottom: 240px;
        display: flex;
        flex-direction: column;
        grid-gap: 80px;
        > div {
            display: flex;
            grid-gap: 72px;
            > div:first-child {
                display: flex;
                img {
                    margin-left: 25px;
                    filter: brightness(0);
                }
            }

            > div:last-child {
                display: flex;
                flex-direction: column;
                grid-gap: 30px;
                > div:first-child {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                }
                > div:last-child {
                    opacity: 0.5;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: justify;
                }
            }
        }
    }
`;

const InformationWrapper = styled.div`
    margin-top: 190px;
    margin-bottom: 160px;
    display: flex;
    flex-direction: column;
    grid-gap: 60px;
    > div {
        display: flex;
        grid-gap: 20px;
        flex-direction: column;
        > div:first-child {
            font-weight: 500;
            font-size: 24px;
            line-height: 40px;
        }
        > div:last-child {
            display: flex;
            grid-gap: 20px;
            position: relative;
            > div:first-child {
                min-width: 70px;
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 2px;
                    width: 38px;
                    left: 17px;
                    top: 12px;
                }
            }
            > div:last-child {
                opacity: 0.5;
                font-size: 16px;
                line-height: 32px;
                text-align: justify;
            }
        }
    }
`;

const AboutScreenWrapper = styled.div`
    background-size: 100% 940px;
    background-repeat: no-repeat;
    background-position: top;
    flex: 1;
    @media all and (max-width: 1080px) {
        padding: 20px;
    }
    img {
        @media all and (max-width: 1000px) {
            flex-wrap: wrap;
        }
    }
    > div {
        width: 1080px;
        @media all and (max-width: 1080px) {
            width: 100%;
            margin-top: 120px;
            padding: 20px;
        }
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        > div:first-child {
            margin: auto;
            font-size: 40px;
            font-weight: bold;
        }
    }
`;

const AboutScreen: React.FC = () => {
    const STATIC_DATA = [
        { name: 'Private sale', value: '21.61' },
        { name: 'Marketing', value: '14.00' },
        { name: 'R&D', value: '14.00' },
        { name: 'Operations', value: '13.67' },
        { name: 'Founding Team', value: '8.33' },
        { name: 'Alpha Quark Alliance', value: '13.33' },
        { name: 'Reserve', value: '15.05' },
    ];

    return (
        <AboutScreenWrapper>
            <div>
                <div>About</div>
                <InformationWrapper>
                    <div>
                        <div>
                            {en['Intellectual property, the most valuable asset in the world']}
                        </div>
                        <div>
                            <div />
                            <div>{en['about.content.01']}</div>
                        </div>
                    </div>
                    <div>
                        <div>{en['What is NFT?']}</div>
                        <div>
                            <div />
                            <div>{en['about.content.03']}</div>
                        </div>
                    </div>
                </InformationWrapper>
                <IPFiWrapper>
                    <div>{en['Alpha Quark IP-fi will make a change for everyone']}</div>
                    <div>
                        <div>
                            <div>
                                <img src={IV} alt="iv" />
                            </div>
                            <div>
                                <div>{en['Investors']}</div>
                                <div>{en['about.content.05']}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <img src={CA} alt="ca" />
                            </div>
                            <div>
                                <div>{en['Creators & Artists']}</div>
                                <div>{en['about.content.06']}</div>
                            </div>
                        </div>
                    </div>
                </IPFiWrapper>
                <FeatureWrapper>
                    <div>{en['Feature']}</div>
                    <div>
                        <div>
                            <div>
                                <img src={Convert} alt="convert" />
                            </div>
                            <div>
                                <div>{en['Get NFT through AQT']}</div>
                            </div>
                            <div>{en['about.content.08']}</div>
                        </div>
                        <div>
                            <div>
                                <img src={Holding} alt="holding" />
                            </div>
                            <div>
                                <div>
                                    {en['Get royalty from intellectual property By holding NFT']}
                                </div>
                            </div>
                            <div>{en['about.content.09']}</div>
                        </div>
                    </div>
                </FeatureWrapper>
                <TriangleWrapper>
                    <div>
                        <div>{en['How you get royalty through NFT on Alpha Quark IP-Fi']}</div>
                    </div>
                    <div>
                        <ImgWrapper>
                            <img src={Triangle} alt="triangle" />
                        </ImgWrapper>
                    </div>
                </TriangleWrapper>
                <TranslateWrapper>
                    <div>
                        <div>{en['Buy and Sell you NFT in marketplace']}</div>
                    </div>
                    <div>
                        <ImgWrapper>
                            <img src={Translate} alt="translate" />
                        </ImgWrapper>
                    </div>
                </TranslateWrapper>
                <PartnerWrapper>
                    <div>
                        <div>{en['Crypto Exchange Partners']}</div>
                        <div>
                            <div>
                                <img src={Bithumb} alt="bithumb" />
                            </div>
                            <div>
                                <img src={Upbit} alt="Upbit" />
                            </div>
                            <div>
                                <img src={GateIO} alt="GateIO" />
                            </div>
                            <div>
                                <img src={GoPAX} alt="GoPAX" />
                            </div>
                            <div>
                                <img src={Hotbit} alt="Hotbit" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>{en['Partners']}</div>
                        <div>
                            <div>
                                <img src={PayProtocol} alt="PayProtocol" />
                            </div>
                            <div>
                                <img src={Adappter} alt="Adappter" />
                            </div>
                            <div>
                                <img src={WXY} alt="WXY" />
                            </div>
                            <div>
                                <img src={FM} alt="FM" />
                            </div>
                            <div>
                                <img src={KMGA} alt="KMGA" />
                            </div>
                            <div>
                                <img src={Sona} alt="Sona" />
                            </div>
                            <div>
                                <img src={MattWallet} alt="MattWallet" />
                            </div>
                            <div>
                                <img src={TresureLend} alt="TresureLend" />
                            </div>
                            <div>
                                <img src={PolyNetwork} alt="PolyNetwork" />
                            </div>
                            <div>
                                <img src={Temp} alt="Temp" />
                            </div>
                        </div>
                    </div>
                </PartnerWrapper>
                <TokenInfoWrapper>
                    <div>{en['Alpha Quark Token Info']}</div>
                    <div>
                        <div>
                            {STATIC_DATA.map((e, i) => {
                                return (
                                    <div key={i}>
                                        <div>{`${e.value}%`}</div>
                                        <div>{e.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <ImgWrapper>
                                <img src={Doughnut} alt="doughnut" />
                            </ImgWrapper>
                        </div>
                        <div>{en['about.content.10']}</div>
                    </div>
                </TokenInfoWrapper>
            </div>
        </AboutScreenWrapper>
    );
};
export default AboutScreen;
