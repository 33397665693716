import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { usePrevious } from '../../hooks/usePrevious';
import { ChainIdReverse } from '../../utils/enums';
import { CHAINS } from '../../utils/values';
import { formatLongText } from '../../utils/formatters';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 11;
    color: white;
    display: flex;
    flex-direction: column;
    > div:first-child {
        width: 100%;
        margin: auto;
        text-align: center;
        padding: 20px;
    }
    h1 {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
    button {
        margin: auto;
        border: 1px solid #fff;
        padding: 10px;
        background: none;
        border-radius: 8px;
        color: white;
        margin-top: 20px;
    }
    span {
        opacity: 0.6;
        color: #3ec7eb;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }
`;

const ChainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    * {
        font-size: 13px;
        line-height: 18px;
    }
    a {
        opacity: 0.6;
        color: #3ec7eb;
        font-size: 14px;
        text-decoration: underline;
    }
    > div {
        display: flex;
        flex-direction: column;
        > div {
            display: flex;
            > div:last-child {
                margin-left: auto;
            }
        }
    }
    h3 {
        text-align: left;
        font-size: 17px;
    }
`;

const TableHeader = styled.div`
    display: flex;
    > div {
        min-width: 200px;
        max-width: 200px;
    }
`;

const Repeater = styled.div`
    line-height: 40px;
    display: flex;
    flex-direction: column;
    > div {
        display: flex;
        > div {
            min-width: 200px;
            max-width: 200px;
            padding: 10px;
            align-self: center;
        }
    }
`;

const Controller = styled.div`
    display: flex;
    margin: auto;
    justify-content: center;
    > div {
        margin: 0 10px;
        margin-top: 20px;
        padding: 10px;
        cursor: pointer;
    }
`;

const OverflowWrapper = styled.div`
    overflow-y: auto;
`;

export const TransactionHistory: React.FC<{
    close: () => void;
    openTransaction: (e: any) => void;
    address: string;
}> = ({ close, address, openTransaction }) => {
    const [{ pageNo, totalPage, transactions }, setPage] = useState({
        pageNo: 0,
        totalCount: null,
        totalPage: null,
        transactions: [],
    });
    
    const prevPageNo = usePrevious(pageNo);

    const fetchTransactions = async () => {
        try {
            const { data } = await axios.post(
                'https://bridge.poly.network/v1/transactionsofaddress',
                {
                    Addresses: [address],
                    PageNo: pageNo,
                    PageSize: 5,
                }
            );
            setPage((p) => ({
                ...p,
                totalCount: data.TotalCount,
                totalPage: data.TotalPage,
                transactions: data.Transactions,
            }));
        } catch (e) {
            //addToast('fetch transactions error', { appearance: 'error' });
        }
    };

    const handlePageNo = (direction: number) => {
        setPage((p) => ({
            ...p,
            pageNo:
                1 * direction + pageNo >= 0 && totalPage > 1 * direction + pageNo
                    ? 1 * direction + pageNo
                    : pageNo,
        }));
    };

    useEffect(() => {
        if (prevPageNo !== pageNo) {
            fetchTransactions();
        }
    }, [pageNo]);

    useEffect(() => {
        if (address) {
            fetchTransactions();
        }
    }, [address]);

    return (
        <Container>
            <div>
                <h1>The historical records</h1>
                <OverflowWrapper>
                    <TableHeader>
                        <div>Source Chain</div>
                        <div>Destination Chain</div>
                        <div>Amount</div>
                        <div>Fee</div>
                        <div>Status</div>
                    </TableHeader>
                    <Repeater>
                        {transactions?.map((e) => {
                            return (
                                <div key={e?.Hash}>
                                    {e?.TransactionState?.filter((e) => e.ChainId !== 0).map(
                                        (e) => {
                                            return (
                                                <ChainWrapper key={e.Hash}>
                                                    <div>
                                                        <h3>{ChainIdReverse[e.ChainId]}</h3>
                                                        <div>
                                                            {e.Hash ? (
                                                                <a
                                                                    href={CHAINS.find(
                                                                        (i) => i.id === e.ChainId
                                                                    ).explorerUrl.replace(
                                                                        '{txHash}',
                                                                        e.Hash
                                                                    )}
                                                                    target="_blank"
                                                                    rel="noreferrer">
                                                                    {`Hash: ${formatLongText(
                                                                        e.Hash,
                                                                        {
                                                                            headTailLength: 8,
                                                                        }
                                                                    )}`}
                                                                </a>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </ChainWrapper>
                                            );
                                        }
                                    )}
                                    <div>{`${e.TransferAmount} ${e.Token.Name}`}</div>
                                    <div>{`${e.FeeAmount} ${e.FeeToken?.Name}`}</div>
                                    <div>
                                        <span onClick={() => openTransaction(e?.Hash)}>Status</span>
                                    </div>
                                </div>
                            );
                        })}
                    </Repeater>
                </OverflowWrapper>
                <Controller>
                    <div onClick={() => handlePageNo(-1)}>{'<'}</div>
                    <div>{`${pageNo + 1} / ${totalPage ? totalPage : 1}`}</div>
                    <div onClick={() => handlePageNo(1)}>{'>'}</div>
                </Controller>
                <button onClick={close}>Close</button>
            </div>
        </Container>
    );
};

